import React, { useState, useEffect, useCallback } from "react";
import { ethers } from "ethers";
import "./claim.css";

import Config from "../config";

import useProofs from "../hooks/useProofs";
import useWeb3 from "../hooks/useWeb3";
import useAirdropHistory from "../hooks/useAirdropHistory";

export default function Claim() {
  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require("ix2");
    //document.dispatchEvent(new Event('readystatechange'))
  });

  const [
    provider,
    signer,
    address,
    contractWatts,
    contractClaim,
    contractTransfer,
    contractSlotie,
    contractBridge,
    isConnected,
    chainId,
    tryAutoConnect,
    triedAutoConnecting,
    setTriedAutoConnecting,
    connect,
    isConnecting,
    isCorrectChain
  ] = useWeb3();

  const claimStart = Config.DATA.CLAIM_START;
  const now = () => Math.floor(Date.now() / 1000);

  const [wattsBalance, setWattsBalance] = useState(undefined);
  const [wattsClaimable, setWattsClaimable] = useState(undefined);
  const [slotieBalance, setSlotieBalance] = useState(undefined);

  const isDataLoaded = () => wattsBalance && wattsClaimable && slotieBalance;
  const isClaimStarted = () => now() >= claimStart;

  const isSlotieBalanceZero = () => (isDataLoaded() ? slotieBalance.eq(0) : true);
  const isWattsClaimableZero = () => (isDataLoaded() ? wattsClaimable.eq(0) : true);

  const [ehr_proofs, pre_claim_proofs] = useProofs(address);
  const [earnings, claims] = useAirdropHistory(address);
  const [openCollapse, setOpenCollapse] = useState({});
  
  const twoDecimals = str => {
    //console.log(str)
    const split = str.split(".");
    //console.log(split)
    if (split.length > 1) {
      return split[0] + "." + split[1].substring(0, 2);
    } else {
      return str;
    }
  };

  const getBalances = async () => {
    if (
      address && 
      contractWatts && 
      contractClaim && 
      contractSlotie && 
      ehr_proofs && 
      pre_claim_proofs
    ) {
      contractWatts
        .balanceOf(address)
        .then(value => setWattsBalance(value))
        .catch(e => console.log(e));

      let nonce;
      if (isEth()) {
        nonce = (await contractClaim.addressToEHRNonce(address)).toNumber();
      } else {
        nonce = (await contractWatts.userClaimNonce(address)).toNumber();
      }

      let ehrProof = [];
      let ehrAmount = ethers.BigNumber.from(0);
      if (ehr_proofs && ehr_proofs.length > 0 && parseInt(ehr_proofs[2]) === nonce) {
        ehrAmount = ethers.BigNumber.from(ehr_proofs[1]);
        ehrProof = ehr_proofs[0];
      }

      if (isEth()) {
        let preClaimAmount = 0;
        let preClaimProof = [];
        let preClaimed = (await contractClaim.addressToPreClaim(address)).toNumber();

        if (preClaimed === 0 && pre_claim_proofs && pre_claim_proofs.length > 0) {
          preClaimAmount = ethers.BigNumber.from(pre_claim_proofs[1]);
          preClaimProof = pre_claim_proofs[0];
        }

        contractClaim
          .slotieGetClaimableBalance(address, preClaimAmount, ehrAmount, nonce, preClaimProof, ehrProof)
          .then(value => {
            setWattsClaimable(value);
          })
          .catch(e => console.log(e));
      } else {
        setWattsClaimable(ehrAmount)
      }
      
      contractSlotie
        .balanceOf(address)
        .then(value => setSlotieBalance(value))
        .catch(e => console.log(e));
    }
  };

  const isEth = () => {
    if (!chainId) return false;
    return chainId === "1" || chainId === "5";
  }

  useEffect(() => {
    getBalances();
  }, [address, contractWatts, contractClaim, contractSlotie, ehr_proofs, pre_claim_proofs, chainId]);

  useEffect(() => {
    if (provider && contractWatts && contractClaim && contractSlotie && ehr_proofs && pre_claim_proofs) {
      provider.on("block", _ => {
        console.log("block update");
        getBalances();
      });
      return () => provider.off("block");
    }
  }, [provider, contractWatts, contractClaim, contractSlotie, ehr_proofs, pre_claim_proofs]);
  //useOnBlock(injectedProvider, () => console.log("avaa"));

  const [isClaiming, setIsClaiming] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isWattsClaimed, setIsWattsClaimed] = useState(false);

  const [status, setStatus] = useState(0);
  const [txId, setTxId] = useState("");

  useEffect(() => {
    checkTx();
  }, [status]);

  const checkTx = () => {
    if (provider) {
      const txId = localStorage.getItem("PENDING_TX");
      console.log("txId", txId);
      if (txId && txId !== "") {
        provider.getTransactionReceipt(txId).then(receipt => {
          console.log("getTransactionReceipt", receipt);
          if (receipt) {
            localStorage.setItem("PENDING_TX", "");
            setIsClaiming(false);
            setStatus(0);
            setTxId(receipt.transactionHash);
            if (receipt.status == true) {
              setIsWattsClaimed(true);
            } else {
              setIsError(true);
            }
            getBalances();
          } else {
            setTimeout(checkTx, 1000);
          }
        });
      }
    }
  };

  const openDropdown = index => {
    console.log(index, openCollapse[index]);
    setOpenCollapse(current => ({ ...current, [index]: !openCollapse[index] }));
  };

  const claim = async () => {
    setIsClaiming(true);

    let ehrAmount = 0;
    let ehrProof = [];
    let nonce;

    if (isEth()) {
      nonce = (await contractClaim.addressToEHRNonce(address)).toNumber();
    } else {
      nonce = (await contractWatts.userClaimNonce(address)).toNumber();
    }

    if (ehr_proofs && ehr_proofs.length > 0 && parseInt(ehr_proofs[2]) === nonce) {
      ehrAmount = ethers.BigNumber.from(ehr_proofs[1]);
      ehrProof = ehr_proofs[0];
    }

    if (isEth()) {
      let preClaimAmount = 0;
      let preClaimProof = [];
      let preClaimed = (await contractClaim.addressToPreClaim(address)).toNumber();

      if (preClaimed === 0 && pre_claim_proofs && pre_claim_proofs.length > 0) {
        preClaimAmount = ethers.BigNumber.from(pre_claim_proofs[1]);
        preClaimProof = pre_claim_proofs[0];
      }

      contractClaim
        .connect(signer)
        .slotieClaim(preClaimAmount, ehrAmount, nonce, preClaimProof, ehrProof)
        .then(response => {
          console.log("response", response);
          localStorage.setItem("PENDING_TX", response.hash);
          setStatus(1);
          return response.hash;
        })
        .catch(error => {
          console.log(error);
          setStatus(0);
          setIsClaiming(false);
          setIsError(true);
        });
      } else {
        contractWatts
          .connect(signer)
          .claim(ehrAmount, nonce, ehrProof)
          .then(response => {
            console.log("response", response);
            localStorage.setItem("PENDING_TX", response.hash);
            setStatus(1);
            return response.hash;
          })
          .catch(error => {
            console.log(error);
            setStatus(0);
            setIsClaiming(false);
            setIsError(true);
          });
      }
  };

  return (
    <div className="page-wrapper">
      <div className="nav">
        <div
          data-animation="default"
          data-collapse="medium"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="navbar w-nav"
        >
          <div className="wrapper navbar-wrapper">
            <div className="container navbar-container">
              <a href="https://watts.slotie.com" className="brand w-nav-brand">
                <img src="images/Slotie-Logo.svg" loading="eager" alt="Slotie Logo." className="logo nav-logo" />
                <div className="text logo-text">Slotie</div>
              </a>
              <nav role="navigation" className="nav-menu w-nav-menu">
                <div className="grid nav-menu-grid">
                  <div className="grid nav-links-grid">
                    <a href="index.html" className="nav-link w-nav-link">
                      Home
                    </a>
                  </div>
                  <div className="grid social-links-grid">
                    <a
                      id="w-node-_8f78bbae-74c8-aa00-d66c-8c5459c9010e-9ab278cb"
                      href="https://twitter.com/SlotieNft"
                      target="_blank"
                      className="link-block social-link w-inline-block"
                      rel="noreferrer"
                    >
                      <img
                        src="images/Logo-white-2.svg"
                        loading="eager"
                        alt="Twitter Logo."
                        className="icon social-icon"
                      />
                    </a>
                    <a
                      href="https://discord.gg/slotie"
                      target="_blank"
                      className="link-block social-link w-inline-block"
                      rel="noreferrer"
                    >
                      <img
                        src="images/Discord-Logo-White-1.svg"
                        loading="eager"
                        alt="Discord Logo."
                        className="icon social-icon"
                      />
                    </a>
                  </div>
                </div>
                <div className="horizontal-line navbar-line mobile-navbar-line"></div>
              </nav>
              <div className="menu-button w-nav-button">
                <div className="w-icon-nav-menu"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="horizontal-line navbar-line"></div>
      </div>

      {
        isCorrectChain === false ? (
          <section id="Claim" className="section wallet-section">
            <div className="container"> 
            <div className="wrapper card">
              <h3 className="heading heading-xl no-margin" style={{ textAlign: "center" }}>
                Your wallet is connected to an unsupported network. Please switch your network to Ethereum or Binance Smart Chain.
              </h3>
            </div>
            </div>
          </section>
        ) : (
          <>
            <section id="Claim" className="section wallet-section">
              <div className="container">
                <div className="wrapper heading-block-wrapper">
                  <div className="horizontal-line grow"></div>
                  <div className="spacer _1em"></div>
                  <h1 className="heading heading-xl no-margin">Claim Watts</h1>
                  <div className="spacer _1em"></div>
                  <div className="horizontal-line grow"></div>
                </div>
                <div className="spacer _2em"></div>
                <div className="wrapper card">
                  <div className="grid wallet-grid">
                    <div className="wrapper">
                      <h2>Your journey begins here.</h2>
                      <p className="text">
                        Each Slotie is able to claim <strong>10 Watts  tokens per day</strong> and token supply is unlimited!
                      </p>

                      {!isConnected && !isConnecting && (
                        <a
                          onClick={e => {
                            e.preventDefault();
                            connect();
                          }}
                          href="#"
                          className="button w-button"
                        >
                          Connect wallet
                        </a>
                      )}

                      {isConnecting && !isConnected && (
                        <div
                          data-w-id="4a714206-6628-824b-d7eb-e3532f4fa8a8"
                          data-animation-type="lottie"
                          data-src="https://uploads-ssl.webflow.com/61a2301c1aba757f9b2521aa/61a2301c1aba75799b2521d9_lottieflow-loading-04-1-ffffff-easey.json"
                          data-loop="1"
                          data-direction="1"
                          data-autoplay="1"
                          data-is-ix2-target="0"
                          data-renderer="svg"
                          data-default-duration="3.1458333333333335"
                          data-duration="0"
                          className="lottie loading-lottie"
                        ></div>
                      )}

                      {isConnected && (
                        <div className="tag connected-tag">
                          Wallet Connected<span className="span"></span>
                        </div>
                      )}
                    </div>

                    <div className="wallet-line"></div>

                    <div id="w-node-_6cbb3016-9612-9d1b-574c-2c7813d436db-9ab278cb" className="wrapper wallet-column right">
                      {isConnected && isDataLoaded() && (
                        <>
                          {(!isWattsClaimableZero() || !isSlotieBalanceZero()) && (
                            <div className="wrapper wallet-block wallet-phase-01">
                              <div className="wrapper wallet-row">
                                <div className="heading wallet-heading">Number of Sloties:</div>
                                <div className="tag wallet-tag">
                                  {slotieBalance.toString()}
                                  <span className="span"></span>
                                </div>
                              </div>
                              <div className="wrapper wallet-row">
                                <div className="heading wallet-heading">Total Watts to claim:</div>
                                <div className="tag wallet-tag">
                                  {twoDecimals(ethers.utils.formatEther(wattsClaimable.toString()))}
                                  <span className="span"></span>
                                </div>
                              </div>
                              <div className="wrapper wallet-row">
                                <div className="heading wallet-heading">Total Balance:</div>
                                <div className="tag wallet-tag">
                                  {twoDecimals(ethers.utils.formatEther(wattsBalance.toString()))}
                                  <span className="span"></span>
                                </div>
                              </div>
                              {!isClaimStarted() && (
                                <div className="text text-small">You can only claim your Watts after Feb 11.</div>
                              )}
                            </div>
                          )}

                          {/*isSlotieBalanceZero() && !isWattsClaimableZero() && (
                            <div className="wrapper wallet-block wallet-phase-02">
                              <div className="wrapper wallet-row">
                                <div className="heading wallet-heading">Number of Sloties:</div>
                                <div className="tag wallet-tag">
                                  {slotieBalance.toString()}
                                  <span className="span"></span>
                                </div>
                              </div>
                              <div className="wrapper wallet-row">
                                <div className="heading wallet-heading">Total Watts to claim:</div>
                                <div className="tag wallet-tag">
                                  {twoDecimals(ethers.utils.formatEther(wattsClaimable.toString()))}
                                  <span className="span"></span>
                                </div>
                              </div>
                              <div className="wrapper wallet-row">
                                <div className="heading wallet-heading">Total Balance:</div>
                                <div className="tag wallet-tag">
                                  {twoDecimals(ethers.utils.formatEther(wattsBalance.toString()))}
                                  <span className="span"></span>
                                </div>
                              </div>
                              <div className="text text-small">
                                You cannot claim your Watts because you are not a current Slotie owner.
                              </div>
                            </div>
                          )*/}

                          {isSlotieBalanceZero() && isWattsClaimableZero() && (
                            <div className="wrapper wallet-block wallet-phase-03">
                              <div className="wrapper wallet-row">
                                <div className="heading wallet-heading">Number of Sloties:</div>
                                <div className="tag wallet-tag">
                                  {slotieBalance.toString()}
                                  <span className="span"></span>
                                </div>
                              </div>
                              <div className="wrapper wallet-row">
                                <div className="heading wallet-heading">Total Watts to claim:</div>
                                <div className="tag wallet-tag">
                                  {twoDecimals(ethers.utils.formatEther(wattsClaimable.toString()))}
                                  <span className="span"></span>
                                </div>
                              </div>
                              <div className="wrapper wallet-row">
                                <div className="heading wallet-heading">Total Balance:</div>
                                <div className="tag wallet-tag">
                                  {twoDecimals(ethers.utils.formatEther(wattsBalance.toString()))}
                                  <span className="span"></span>
                                </div>
                              </div>
                              <div className="text text-small">You cannot claim your Watts because your balance is zero.</div>
                            </div>
                          )}

                          <div className="wrapper claim-watts-wrapper">
                            {isClaimStarted() && !isWattsClaimableZero() && !isClaiming && !isWattsClaimed && !isError && (
                              <a
                                onClick={e => {
                                  e.preventDefault();
                                  claim();
                                }}
                                href="#"
                                className="button w-button"
                              >
                                Claim your Watts
                              </a>
                            )}

                            {isClaiming && (
                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <div
                                  data-w-id="7c63db2b-ec3c-cf00-594d-cf6c121b75f3"
                                  data-animation-type="lottie"
                                  data-src="https://uploads-ssl.webflow.com/61a2301c1aba757f9b2521aa/61a2301c1aba75799b2521d9_lottieflow-loading-04-1-ffffff-easey.json"
                                  data-loop="1"
                                  data-direction="1"
                                  data-autoplay="1"
                                  data-is-ix2-target="0"
                                  data-renderer="svg"
                                  data-default-duration="3.1458333333333335"
                                  data-duration="0"
                                  className="lottie loading-lottie"
                                ></div>

                                {status === 0 && (
                                  <div className="text text-small" style={{ marginLeft: 10 }}>
                                    Please confirm the transaction.
                                  </div>
                                )}

                                {status === 1 && (
                                  <div className="text text-small" style={{ marginLeft: 10 }}>
                                    Transaction is loading.
                                  </div>
                                )}
                              </div>
                            )}

                            {!isClaiming && isWattsClaimed && (
                              <>
                                <div className="watts-claimed">
                                  <div className="tag connected-tag">
                                    <span className="span">Watts claimed</span>
                                  </div>
                                  <div className="text text-small">{isEth() ? "0x5058B77CBd029F56A11Bd56326519e3Ec0081cD0" : "0x5fdB2B0C56Afa73B8ca2228e6aB92Be90325961d"}</div>
                                </div>
                                <span style={{ fontSize: 11, marginTop: 10 }}>
                                  To add Watts on Metamask Wallet, go to the Assets in your Metamask Profile, click Import
                                  Tokens, paste this adress and push the Add Button
                                </span>
                              </>
                            )}
                            {!isClaiming && isError && <div className="tag error-tag">Claim unsuccessful</div>}
                          </div>
                        </>
                      )}
                      {!isConnected && (
                        <>
                          <div className="wrapper wallet-block wallet-phase-01">
                            <div className="wrapper wallet-row">
                              <div className="heading wallet-heading">Please connect your wallet to check your balance.</div>
                            </div>
                          </div>
                        </>
                      )}

                      {isConnected && !isDataLoaded() && (
                        <div className="wrapper wallet-block wallet-phase-01">
                          <div
                            data-w-id="4a714206-6628-824b-d7eb-e3532f4fa8a8"
                            data-animation-type="lottie"
                            data-src="https://uploads-ssl.webflow.com/61a2301c1aba757f9b2521aa/61a2301c1aba75799b2521d9_lottieflow-loading-04-1-ffffff-easey.json"
                            data-loop="1"
                            data-direction="1"
                            data-autoplay="1"
                            data-is-ix2-target="0"
                            data-renderer="svg"
                            data-default-duration="3.1458333333333335"
                            data-duration="0"
                            className="lottie loading-lottie"
                          ></div>
                          <div className="text text-small">Loading your data.</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id="Claim-description" className="section" style={{ paddingTop: 100, marginTop: "-100px" }}>
              <div className="container">
                <div className="wrapper card">
                  <div className="grid wallet-grid">
                    <div className="wrapper">
                      <h2 style={{marginBottom: "5px"}}>Weekly WATTS Airdrop History</h2>
                      <p style={{fontSize: "12px"}}>
                          Each week you see is based on the dates we did airdrops. 
                          Some weeks can be more or less than 7 days.
                          The hold period of each Slotie shows you how long the slotie
                          was held in during the airdrop period. It can be the full airdrop period
                          or less.
                      </p>
                      <div className="wrapper wallet-column" style={{ height: "300px" }}>
                        {earnings.map(item => (
                          <div className="list-item-group">
                            <div className="list-item">
                              <h4 className={`${openCollapse[item.id] ? " open" : ""}`} onClick={() => openDropdown(item.id)}>
                                {item.title}
                                <svg
                                  style={{ alignSelf: "center" }}
                                  width="15"
                                  height="8"
                                  viewBox="0 0 10 6"
                                  fill="white"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M5 6L0.669872 0.75L9.33013 0.75L5 6Z" fill="#fff" />
                                </svg>
                              </h4>
                              <div className={`list-body${openCollapse[item.id] ? " open" : ""}`}>
                                <p style={{ marginTop: "5px" }}>Earned this week: {item.totalEarned} WATTS</p>
                                <hr />
                                {item.slotieEarnings.map(earnings => {
                                  return (
                                    <>
                                      <p>Slotie #{earnings.name}</p>
                                      <p>Earned: {earnings.earned} WATTS</p>
                                      <p>Hold period: {earnings.duration}</p>
                                      <hr />
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="wallet-line" />
                    <div className="wrapper">
                    <h2 style={{marginBottom: "5px"}}>Weekly WATTS Airdrop Claim History</h2>
                      <p style={{fontSize: "12px"}}>
                          Here you see all the WATTS you claimed since we started doing the weekly airdrops.
                          Note that claims done after the latest airdrop are not recorded and are only added
                          on the next airdrop.
                      </p>
                      <div className="wrapper wallet-column" style={{ height: "300px" }}>
                        {claims.map(item => (
                          <div className="list-item-group">
                            <div className="list-item">
                              <h4 className={`${openCollapse[item.id] ? " open" : ""}`} onClick={() => openDropdown(item.id)}>
                                {item.title}
                                <svg
                                  style={{ alignSelf: "center" }}
                                  width="15"
                                  height="8"
                                  viewBox="0 0 10 6"
                                  fill="white"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M5 6L0.669872 0.75L9.33013 0.75L5 6Z" fill="#fff" />
                                </svg>
                              </h4>
                              <div className={`list-body${openCollapse[item.id] ? " open" : ""}`}>
                                <p style={{ marginTop: "5px" }}>Claimed this week: {item.totalClaimed} WATTS</p>
                                <hr />
                                {item.claims.map(claim => {
                                  return (
                                    <>
                                      <p>Date: {claim.date}</p>
                                      <p>Amount: {claim.amount} WATTS</p>
                                      <hr />
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )
      }
      
      <footer className="section footer-section">
        <div className="container footer-container">
          <div className="grid footer-grid">
            <a
              href="https://www.slotie.com"
              id="w-node-fa8bc22b-940a-a591-4ef0-fb26e11756cb-9ab278cb"
              className="brand w-nav-brand"
            >
              <img src="images/Slotie-Logo.svg" loading="eager" alt="Slotie Logo." className="logo footer-logo" />
              <div className="text logo-text footer-logo-text">Slotie</div>
            </a>
            <div className="grid footer-subgrid">
              <div className="text">
                Become a member of the strongest and fast growing DeFi gambling community.
                <br />
              </div>
              <div className="grid social-links-grid">
                <a
                  id="w-node-_85111364-03ec-d5ca-8481-6c611792f731-9ab278cb"
                  href="https://twitter.com/SlotieNft"
                  target="_blank"
                  className="link-block social-link w-inline-block"
                  rel="noreferrer"
                >
                  <img src="images/Logo-white-2.svg" loading="eager" alt="Twitter Logo." className="icon social-icon" />
                </a>
                <a
                  href="https://discord.gg/slotie"
                  target="_blank"
                  className="link-block social-link w-inline-block"
                  rel="noreferrer"
                >
                  <img
                    src="images/Discord-Logo-White-1.svg"
                    loading="eager"
                    alt="Discord Logo."
                    className="icon social-icon"
                  />
                </a>
              </div>
            </div>
            <div className="horizontal-line grow"></div>
            <div className="grid footer-subgrid">
              <div className="text text-small">
                © 2022 Slotie NFT. All rights reserved.
                <br />
              </div>
              <a href="https://www.slotie.com/terms" target="_blank" className="link terms-link" rel="noreferrer">
                Terms &amp; Conditions
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
