import { useState, useEffect } from "react";
import { ethers } from "ethers";
import axios from "axios";

let earningsUri = "https://d30t5s1adm1of2.cloudfront.net/watts/earnings.json";
let claimsUri = "https://d30t5s1adm1of2.cloudfront.net/watts/claims.json";

export default function useAirdropHistory(address) {
  const [Earnings, SetEarnings] = useState();
  const [Claims, SetClaims] = useState();

  const [earnings, setEarnings] = useState([]);
  const [claims, setClaims] = useState([]);

  function monthToString(month) {
    if (month === 1) return "January";
    else if (month === 2) return "February";
    else if (month === 3) return "March";
    else if (month === 4) return "April";
    else if (month === 5) return "May";
    else if (month === 6) return "June";
    else if (month === 7) return "July";
    else if (month === 8) return "August";
    else if (month === 9) return "September";
    else if (month === 10) return "October";
    else if (month === 11) return "November";
    else if (month === 12) return "December";
  }

  function unixTimeStampToDate(timestamp) {
    let milisecs = timestamp * 1000;
    let date = new Date(milisecs);

    let days = date.getDate();
    let month = date.getMonth();

    return days + " " + monthToString(month + 1);
  }

  function unixTimeStampToPeriod(timestamp) {
    let n_days = Math.floor(timestamp / 86400);
    let n_days_res = timestamp % 86400;
    let n_hours = Math.floor(n_days_res / 3600);

    if (n_days > 0 && n_hours > 0) return n_days + " days and " + n_hours + " hours";
    else if (n_days > 0 && n_hours === 0) return n_days + " days";
    else if (n_days === 0 && n_hours > 0) return n_hours + " hours";
    else return "less than 1 hour";
  }

  async function fetchAirdropHistory() {
    let earningsRes = await axios.get(earningsUri);
    if (earningsRes && earningsRes.data && Object.keys(earningsRes.data).length > 0) {
      SetEarnings(earningsRes.data);
    }

    let claimsRes = await axios.get(claimsUri);
    if (claimsRes && claimsRes.data && Object.keys(claimsRes.data).length > 0) {
      SetClaims(claimsRes.data);
    }
  }

  useEffect(() => {
    fetchAirdropHistory();
  }, []);

  useEffect(() => {
    if (address && Earnings && Claims && Object.keys(Earnings).length > 0 && Object.keys(Claims).length > 0) {
      let earningsHistory = Earnings[address.toLowerCase()];

      if (earningsHistory && Object.keys(earningsHistory).length > 0) {
        let _earnings = Object.keys(earningsHistory)
          .map((earningsWeek, i) => {
            let startEnd = earningsWeek.split("-");
            let startTimestamp = startEnd[0];
            let endTimestamp = startEnd[1];

            let earningsInWeek = earningsHistory[earningsWeek];

            if (earningsInWeek.length === 0) return undefined;

            let totalDuration = earningsInWeek.reduce((acc, v) => {
              return acc + v.duration;
            }, 0);
            let totalEarned = ((totalDuration * 10) / 86400).toFixed(2);

            let dropDowndata = earningsInWeek.map(e => {
              return {
                name: e.slotie,
                duration: unixTimeStampToPeriod(e.duration),
                earned: ((e.duration * 10) / 86400).toFixed(2),
              };
            });

            return {
              id: "earn" + i,
              title: unixTimeStampToDate(startTimestamp) + " - " + unixTimeStampToDate(endTimestamp),
              totalEarned,
              slotieEarnings: dropDowndata,
            };
          })
          .filter(d => d !== undefined);

        setEarnings(_earnings);
      }

      let claimHistory = Claims[address.toLowerCase()];

      if (claimHistory && Object.keys(claimHistory).length > 0) {
        let _claims = Object.keys(claimHistory)
          .map((claimWeek, i) => {
            let startEnd = claimWeek.split("-");
            let startTimestamp = startEnd[0];
            let endTimestamp = startEnd[1];

            let claimsInWeek = claimHistory[claimWeek];
            if (claimsInWeek.length === 0) return undefined;

            let totalClaimed = parseFloat(ethers.utils.formatEther(
              claimsInWeek.reduce((acc, v) => {
                return acc.add(v.amount);
              }, ethers.BigNumber.from(0)),
            )).toFixed(2);

            let dropDowndata = claimsInWeek.map(e => {
              return {
                date: unixTimeStampToDate(e.timestamp),
                amount: parseFloat(ethers.utils.formatEther(ethers.BigNumber.from(e.amount))).toFixed(2),
              };
            });

            return {
              id: "claim" + i,
              title: unixTimeStampToDate(startTimestamp) + " - " + unixTimeStampToDate(endTimestamp),
              totalClaimed,
              claims: dropDowndata,
            };
          })
          .filter(d => d !== undefined);

        setClaims(_claims);
      }
    }
  }, [address, Earnings, Claims]);

  return [earnings, claims];
}
