import React from "react";
import { Route, Switch } from "react-router-dom";
import { Home, Claim, Transfer, Bridge } from "./views";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/claim">
          <Claim />
        </Route>
        <Route exact path="/transfer">
          <Transfer />
        </Route>
        <Route exact path="/bridge">
          <Bridge />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
