const Config = {
  DATA: {
    CLAIM_START: 1644624000,
  },
  CONTRACTS: {
    WATTS: "WATTS",
    WATTS_BSC: "WATTSBsc",
    WATTS_CLAIM: "WattsClaim",
    WATTS_TRANSFER: "WattsTransferExtensionV2",
    WATTS_TRANSFER_BSC: "WattsTransferExtensionBsc",
    SLOTIE: "Slotie",
    ETH_BRIDGE: "SlotieEthBscBridge",
    BSC_BRIDGE: "SlotieBscEthBridge",
  },
  NETWORK: {
    TARGET_CHAIN_IDs: [1, 56],
    CHAIN_ID_TO_NAME: {
      1: "mainnet",
      5: "goerli",
      56: "bsc",
      97: "bsc_testnet",
    },
    ETH_RPC_URL: "https://mainnet.infura.io/v3/e26409fe0a7343458a12e059c7ada225",
  },
  URLS: {
    BASE: "#",
    ETHERSCAN: "https://testnet.bscscan.com",
  },
  ERRORS: [
    {
      error: "MetaMask Tx Signature: User denied transaction signature",
      message: "Error: You rejected the transaction.",
    },
    {
      error: "insufficient funds for gas * price + value",
      message:
        "Error: You don't have enough Ether in your wallet to complete the transaction. The price for one metasavior is [amount] Ether.",
      insert: true,
    },
  ],
};

export default Config;
