import { useState, useEffect } from "react";
import ehrProofs from "../proofs/ehrProofs.json";
import preClaimProofs from "../proofs/preClaimProofs.json";

export default function useProofs(address) {
  const [ehr_proofs, set_ehr_proofs] = useState(undefined);
  const [pre_claim_proofs, set_pre_claim_proofs] = useState(undefined);

  useEffect(() => {
    if (address) {
      let _pre = preClaimProofs && preClaimProofs.proofs ? preClaimProofs.proofs[address.toLowerCase()] || [] : [];
      let _ehr = ehrProofs && ehrProofs.proofs ? ehrProofs.proofs[address.toLowerCase()] || [] : [];

      if (_pre) {
          set_pre_claim_proofs(_pre);
        }

      if (_ehr) {
          set_ehr_proofs(_ehr);
        }        
    }
  }, [address]);

  return [ehr_proofs, pre_claim_proofs];
}
